import '../styles/app.scss'
import { StateMachineProvider, createStore } from 'little-state-machine'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as fbq from 'src/client/lib/fb/fpixel'
import * as gtag from 'src/client/lib/ga/gtag'
import { ToastStateProvider } from 'src/client/modules/admin/toasts/toasts-context'
import { LoginFallback } from 'src/client/modules/error-boundary/login-fallback/login-fallback'

createStore(
  {
    chosenFlavors: [],
    isProposalFetching: false,
    isProposalFetched: false
  },
  null
)

const errorHandler = (error, errorInfo) => {
  // TODO: adjust or remove!
  console.log('_app Login error:', { error, errorInfo })
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  useEffect(() => {
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (process.browser) {
    router.beforePopState(({ as }): boolean => {
      window.location.href = as

      return false
    })
  }

  return (
    <>
      {/* Google Optimize */}
      <Script
        strategy="afterInteractive"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-PBNX6M8"
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}

      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `
        }}
      />

      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="prefetch" href="/images/mobile-menu-bg.png" />

        <script
          id="gtm"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${gtag.GA_TRACKING_ID}');
          `
          }}
        />

        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1335469,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
          }}
        />

        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src=“https://www.clarity.ms/tag/”+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, “clarity”, “script”, “gjbc8wgxie”);`
          }}
        />
      </Head>

      <StateMachineProvider>
        <ToastStateProvider>
          <ErrorBoundary FallbackComponent={LoginFallback} onError={errorHandler}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </ToastStateProvider>
      </StateMachineProvider>

      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `(function(e,t,o,n,p,r,i)
            {e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function()
            {(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||
            []).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new
            Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")
            
            [0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
            
            vgo('setAccount', '91026894');
            vgo('setTrackByDefault', true);
            vgo('process');`
        }}
      />
    </>
  )
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}

  return {
    pageProps
  }
}

export default MyApp
